import React, { useState, useEffect, useRef } from "react";
import { setVehicle, getVehicle } from "./searchByVehicleFunctions";
import {
  setYearsLS,
  getYearsLS,
  setModelsLS,
  getModelsLS,
  setTrimsLS,
  getTrimsLS,
  setOptionsLS,
  getOptionsLS,
} from "./searchByVehicleFunctions";
import { setFilters, getFilters } from "./filterFunctions";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import Slider from "@mui/material/Slider";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";

//SKELETON
import WheelsCardSkeleton from "../productCard/wheelsCardSkeleton/wheelsCardSkeleton";
import Skeleton from "@mui/material/Skeleton";
import { updateMVehicle } from "../cart/cartFunctions";
import InputVehicleSkeleton from "../skeletons/inputvehicle/inputvehicleskeleton";

import {
  getDispWheelsLS,
  setDispWheelsLS,
  getTotalWheelsLS,
  setTotalWheelsLS,
} from "./searchByVehicleFunctions";

//IMAGES
import filterimage from "../../icons/filter.svg";

//CSS
import * as StyledComponents from "./SearchByVehicle.module.scss";
// Data
import makesData from "../../data/makes.json";
// Components
import ProductCard from "../productCard/productCard";
// Import querry string library
var qs = require("qs");

// Markup
const SearchByVehicle = () => {
  const [Year, setYear] = useState();
  const [Make, setMake] = useState();
  const [Model, setModel] = useState();
  const [Trim, setTrim] = useState();
  const [Option, setOption] = useState();
  const [Size, setSize] = useState();
  const [YearItems, setYearItems] = useState([]);
  const [ModelItems, setModelItems] = useState([]);
  const [TrimOptionItems, setTrimOptionItems] = useState([]);
  const [OptionItems, setOptionItems] = useState([]);
  const [AvalibleSizes, setAvalibleSizes] = useState([]);
  const [DisplayItems, setDisplayItems] = useState({ display: "none" });
  const [FtmNumber, setFtmNumber] = useState();

  //-----------FILTERS--------------------
  const [TOTALWHEELS, setTOTALWHEELS] = useState(0);
  const [DispFilterHeader, setDispFilterHeader] = useState({
    display: "inline",
  });
  const [DispFilterBox, setDispFilterBox] = useState({ display: "none" });
  const [WidthsSelection, setWidthsSelection] = useState([]);
  const [OffsetsSelection, setOffsetsSelection] = useState([]);
  const [BrandsSelection, setBrandsSelection] = useState([]);
  const [PriceSelection, setPriceSelection] = useState([]);
  const [FWIDTHS, setFWIDTHS] = useState([]);
  const [FOFFSETS, setFOFFSETS] = useState([]);
  const [FBRANDS, setFBRANDS] = useState([]);
  const [FMINPRICE, setFMINPRICE] = useState(0);
  const [FMAXPRICE, setFMAXPRICE] = useState(100);
  const [PriceRangeValue, setPriceRangeValue] = useState([
    FMINPRICE,
    FMAXPRICE,
  ]);

  //-----------PAGINATION--------------------
  const [StartIndex, setStartIndex] = useState(9);
  const [EndIndex, setEndIndex] = useState(17);

  const [data, setdata] = useState([]);
  const [isloading, setisloading] = useState(true);

  //-----------NEW--------------------
  function getYears(params) {
    //CLEAR OTHERS
    setYear("");
    setModel("");
    setTrim("");
    setOption("");
    setSize("");

    //dont display cards
    setDisplayItems({ display: "none" });
    //clear years and set to year
    setYearItems([]);
    setYear("Year");
    //clear models options
    setModelItems([]);
    //clear trim
    setTrimOptionItems([]);
    //clear options
    setOptionItems([]);
    //clear sizes
    setAvalibleSizes([]);

    //setVehicle Local Storage
    setVehicle([{}]);
    //getVehicle local storage
    let v = getVehicle();
    //change vehicle make
    v[0].make = params;
    //change Make local storage
    setVehicle(v);
    //change Make useState
    setMake(params);
    //Get data
    for (let i = 0; i < makesData.length; i++) {
      if (makesData[i].make === params) {
        let x = [];
        for (let ii = 0; ii < makesData[i].active.length; ii++) {
          x.push({ year: makesData[i].active[ii] });
        }
        setYearItems(x);
        setYearsLS(x);
      }
    }
  }

  async function getModels(params) {
    //CLEAR OTHERS
    setYear("");
    setModel("");
    setTrim("");
    setOption("");
    setSize("");

    //dont display cards
    setDisplayItems({ display: "none" });
    //clear models options and set to Models
    setModelItems([]);
    setModel("Models");
    //clear trim
    setTrimOptionItems([]);
    //clear options
    setOptionItems([]);
    //clear sizes
    setAvalibleSizes([]);

    //getVehicle local storage
    let v = getVehicle();
    //change vehicle year
    v[0].year = params;
    //change year local storage
    setVehicle(v);
    //change year useState
    setYear(params);
    //Get data
    const query = qs.stringify({
      _where: { name: params + v[0].make },
    });
    await fetch(`https://www.wheels-api.com/models?${query}`)
      .then((res) => res.json())
      .then(
        (result) => {
          setModelItems(result[0].data);
          setModelsLS(result[0].data);
        },
        (error) => {
          console.log(error);
        }
      );
  }

  async function getTrimOption(params) {
    //CLEAR OTHERS
    setModel("");
    setTrim("");
    setOption("");
    setSize("");
    //dont display cards
    setDisplayItems({ display: "none" });
    //clear trim
    setTrimOptionItems([]);
    //clear options
    setOptionItems([]);
    //clear sizes
    setAvalibleSizes([]);

    //getVehicle local storage
    let v = getVehicle();
    //change vehicle model
    v[0].model = params;
    //change model local storage
    setVehicle(v);
    //change model useState
    setModel(params);
    params = params.replace(/ /g, "");
    const query = qs.stringify({
      _where: { name: Year + Make + params },
    });
    await fetch(`https://www.wheels-api.com/submodels?${query}`)
      .then((res) => res.json())
      .then(
        (result) => {
          setTrimOptionItems(result[0].options);
          setTrimsLS(result[0].options);
        },
        (error) => {
          console.log(error);
        }
      );
  }

  function getOption(params) {
    //dont display cards
    setDisplayItems({ display: "none" });
    //clear options
    setOptionItems([]);
    setOption("Options");
    setSize("Size");
    //clear sizes
    setAvalibleSizes([]);

    //getVehicle local storage
    let v = getVehicle();
    //change vehicle trim
    v[0].trim = params;
    //change trim local storage
    setVehicle(v);
    //change trim useState
    setTrim(params);
    for (let i = 0; i < TrimOptionItems.length; i++) {
      if (TrimOptionItems[i].trim === params) {
        setOptionItems(TrimOptionItems[i].options);
        setOptionsLS(TrimOptionItems[i].options);
      }
    }
  }

  async function getSizes(givenParams) {
    //CLEAR OTHERS
    setSize("");
    let params = givenParams;
    if (givenParams === "TOUTES LES OPTIONS") {
      params = "All";
    }
    // console.log(params);
    //getVehicle local storage
    let v = getVehicle();
    //change vehicle option
    v[0].option = params;
    //change option useState
    setOption(givenParams);
    for (let i = 0; i < OptionItems.length; i++) {
      if (OptionItems[i].optionName === params) {
        let query = OptionItems[i].fittingNumber;
        v[0].number = query;
        //change option and number local storage
        setVehicle(v);
        await fetch(
          `https://www.wheels-api.com/fittments?_where[number]=${query}`
        )
          .then((res) => res.json())
          .then(
            (result) => {
              setVehicleData(result[0].data);
            },
            (error) => {
              console.log(error);
            }
          );
      }
    }
  }

  function setVehicleData(ftmtNbrData) {
    //getVehicle local storage
    let v = getVehicle();

    //map querry data
    ftmtNbrData.map((item) => {
      let xcb = item.cb;
      let xpcd = item.pcd;
      let xtpms = item.tpms;
      let xavalibleSizes = item.allDiameters;

      //change vehicle Local Storage
      v[0].cb = xcb;
      v[0].pcd = xpcd;
      v[0].tpms = xtpms;
      v[0].avalibleSizes = xavalibleSizes;

      //set avalible sizes useState
      setAvalibleSizes(xavalibleSizes);

      item.wheels.map((item2) => {
        v[0].plusWheels = item2.pluswheels;
        v[0].minusWheels = item2.minusWheels;
        v[0].liftedwheels = item2.Liftedwheels;
        return <></>;
      });

      //set vehicle LS
      setVehicle(v);
      return <></>;
    });
  }

  function getChosenSize(params) {
    //dont display cards
    setDisplayItems({ display: "none" });
    //clear cards to siplay

    //getVehicle local storage
    let v = getVehicle();
    //change vehicle selectedSize and selectedSizeIndex
    v[0].selectedSize = params;
    //change selectedSize and selectedSizeIndex local storage
    setVehicle(v);
    //change Size useState
    setSize(v[0].selectedSize);

    //sel loading
    setisloading(true);

    //get wheels of selected size
    //getWheelsSize(v[0].number, v[0].selectedSize);

    //Querry selected size to the database
    //getWheels(v[0].number + v[0].selectedSize);
  }

  async function getWheels(wheelfittingnbr) {
    setStartIndex(9);
    setEndIndex(17);
    await fetch(
      `https://www.wheels-api.com/vehiclewheels/${wheelfittingnbr}/0/8`
    )
      .then((res) => res.json())
      .then(
        (result) => {
          //Change displayed wheels on LS
          setDispWheelsLS(result.wheels);

          //set true on car set
          let v = getVehicle();
          v[0].isCarSet = true;
          setVehicle(v);

          //****set avalible wheels to display
          setdata(result.wheels);
          setTOTALWHEELS(result.totalwheels);
          setTotalWheelsLS(result.totalwheels);
          setisloading(false);
          //set data for filters
          setFWIDTHS(result.widths);
          setFOFFSETS(result.offsets);
          setFBRANDS(result.brands);
          setFMINPRICE(result.prices[0]);
          setFMAXPRICE(result.prices[1]);
          setPriceRangeValue([result.prices[0], result.prices[1]]);
          //remove all previus filters
          setWidthsSelection([]);
          setOffsetsSelection([]);
          setBrandsSelection([]);
          //set filters data to LocalStorage
          setFilters([
            {
              w: result.widths,
              of: result.offsets,
              br: result.brands,
              p: [result.prices[0], result.prices[1]],
            },
          ]);
        },
        (error) => {
          console.log(error);
        }
      );
  }

  const handleSubmit = (e) => {
    e.preventDefault();

    let v = getVehicle();
    //IF SELECTED SIZE IS NOT UNDEFINED
    if (Size === undefined || Size === "Size" || Size === "") {
      //DISPLAY ERROR MESSAGE
      setErrorMessage(
        <p className={StyledComponents.errormsg}>
          ⚠ Veuillez entrer toutes les options avant de lancer la recherche ⚠
        </p>
      );
    } else {
      //GET THE WHEELS
      setFtmNumber(v[0].number + v[0].selectedSize);
      getWheels(v[0].number + v[0].selectedSize);

      setDisplayItems({ display: "flex" });

      //set manual vehicle
      v = getVehicle()[0];
      let newMV = v.make + " " + v.year + " " + v.model + " " + v.trim;

      //update local storage vehicle
      updateMVehicle(JSON.stringify({ vehicle: newMV }));

      setErrorMessage("");
    }
  };

  //--------ON PAGE LOAD--------------↓↓↓↓↓↓↓↓↓

  useEffect(() => {
    if (getVehicle().length !== 0 && getVehicle()[0].isCarSet === true) {
      //getVehicle local storage
      let v = getVehicle();
      //set options for useStates
      setYearItems(getYearsLS());
      setModelItems(getModelsLS());
      setTrimOptionItems(getTrimsLS());
      setOptionItems(getOptionsLS());
      setAvalibleSizes(v[0].avalibleSizes);

      //set wheels from LS
      setdata(getDispWheelsLS());
      setTOTALWHEELS(getTotalWheelsLS());
      //Set loading to false
      setisloading(false);
      //change Selections
      setYear(v[0].year);
      setMake(v[0].make);
      setModel(v[0].model);
      setTrim(v[0].trim);
      setOption(v[0].option);
      setSize(v[0].selectedSize);
      setFtmNumber(v[0].number + v[0].selectedSize);
      //display wheels
      setDisplayItems({ display: "flex" });
    }
    if (getFilters().length !== 0) {
      let f = getFilters();
      //set the filters
      setFBRANDS(f[0].br);
      setFWIDTHS(f[0].w);
      setFOFFSETS(f[0].of);
      // setFMINPRICE(f[0].p[0]);
      // setFMAXPRICE(f[0].p[1]);
      //setPriceRangeValue(f[0].p);
    }
  }, []);

  //--------ON PAGE LOAD--------------↑↑↑↑↑↑↑↑↑

  //--------FILTERS--------------↓↓↓↓↓↓↓↓↓↓

  function openFilter() {
    setDispFilterBox({ display: "inline" });
    setDispFilterHeader({ display: "none" });
  }

  function widthsValue(e) {
    let v = parseFloat(e);
    let arr = WidthsSelection;
    if (arr.indexOf(v) === -1) {
      //add to selection
      arr.push(v);
    } else {
      //remove from selection
      let index = arr.indexOf(v);
      arr.splice(index, 1);
    }
    setWidthsSelection(arr);
    // console.log(arr);
  }

  function offsetsValue(e) {
    let v = parseFloat(e);
    let arr = OffsetsSelection;
    if (arr.indexOf(v) === -1) {
      //add to selection
      arr.push(v);
    } else {
      //remove from selection
      let index = arr.indexOf(v);
      arr.splice(index, 1);
    }
    setOffsetsSelection(arr);
    // console.log(arr);
  }

  function brandsValue(e) {
    let arr = BrandsSelection;
    if (arr.indexOf(e) === -1) {
      //add to selection
      arr.push(e);
    } else {
      //remove from selection
      let index = arr.indexOf(e);
      arr.splice(index, 1);
    }
    setBrandsSelection(arr);
    // console.log(arr);
  }

  const handlePriceChange = (event, newValue) => {
    setPriceRangeValue(newValue);
    setPriceSelection(newValue);
  };

  const handleSubmitFilter = () => {
    //CLOSE FILTERS
    setDispFilterBox({ display: "none" });

    //IF FILTERS ARE NOT EMPTY
    if (
      WidthsSelection.length !== 0 ||
      OffsetsSelection.length !== 0 ||
      BrandsSelection.length !== 0 ||
      PriceSelection.length !== 0
    ) {
      //CHANGE LOADING STATUS
      setisloading(true);

      //FETCH RESULTS
      // console.log(WidthsSelection);
      // console.log(OffsetsSelection);
      // console.log(BrandsSelection);
      // console.log(PriceSelection);

      getfilterwheels();

      async function getfilterwheels() {
        await fetch(`https://www.wheels-api.com/getfilterwheels`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            number: FtmNumber,
            widths: WidthsSelection,
            offsets: OffsetsSelection,
            brands: BrandsSelection,
            prices: PriceSelection,
          }),
        })
          .then((res) => res.json())
          .then(
            (result) => {
              // console.log(result);
              //SET TOTAL WHEELS
              setTOTALWHEELS(result.length);
              //SET NEW WHEELS DISPLAY
              setdata(result);
              //SET LOADING STATE TO FALSE
              setisloading(false);
            },
            (error) => {
              console.log(error);
            }
          );
      }

      //CLEAR FILTERS
      setWidthsSelection([]);
      setOffsetsSelection([]);
      setBrandsSelection([]);
    }
    setDispFilterHeader({ display: "inline" });
  };

  function returnFilters() {
    return (
      <>
        <Button
          variant="contained"
          fullWidth={true}
          sx={{
            backgroundColor: "var(--darkgreen)",
            borderRadius: "0",
            [`&:hover, &.Mui-focusVisible`]: {
              backgroundColor: "var(--darkgreen)",
            },
            height: "45px",
          }}
          style={DispFilterHeader}
          onClick={() => openFilter()}
        >
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <div
              style={{
                display: "flex",
                alignItems: "center",
              }}
            >
              <img src={filterimage} alt="" width="24" height="24" />
              <p style={{ margin: 0 }}>&nbsp;AFFICHER LES FILTRES</p>
            </div>
            <p style={{ margin: 0 }}>{TOTALWHEELS} mags</p>
          </div>
        </Button>
        <div className={StyledComponents.filters} style={DispFilterBox}>
          {/*<p>Produits affiches: {totalwheels}</p>*/}
          <h4>Largeurs : </h4>
          <FormGroup row>
            {FWIDTHS.map((item) => {
              return (
                <FormControlLabel
                  key={Math.random()}
                  control={<Checkbox />}
                  label={item}
                  value={item}
                  onChange={(e) => widthsValue(e.target.value)}
                />
              );
            })}
          </FormGroup>
          <h4>Offsets : </h4>
          <FormGroup row>
            {FOFFSETS.map((item) => {
              return (
                <FormControlLabel
                  key={Math.random()}
                  control={<Checkbox />}
                  label={item}
                  value={item}
                  onChange={(e) => offsetsValue(e.target.value)}
                />
              );
            })}
          </FormGroup>
          <h4>Marques : </h4>
          <FormGroup row>
            {FBRANDS.map((item) => {
              return (
                <FormControlLabel
                  key={Math.random()}
                  control={<Checkbox />}
                  label={item}
                  value={item}
                  onChange={(e) => brandsValue(e.target.value)}
                />
              );
            })}
          </FormGroup>
          {/* <h4>Prix : </h4>
          <Box sx={{ margin: "3em 2em 0 2em" }}>
            <Slider
              getAriaLabel={() => "Price range"}
              value={PriceRangeValue}
              onChange={handlePriceChange}
              min={FMINPRICE}
              max={FMAXPRICE}
              valueLabelDisplay="on"
              sx={{
                color: "var(--darkgreen)",
              }}
            />
          </Box> */}
          <br />
          <Button
            variant="contained"
            fullWidth={true}
            sx={{
              backgroundColor: "var(--darkgreen)",
              borderRadius: "0",
              [`&:hover, &.Mui-focusVisible`]: {
                backgroundColor: "var(--darkgreen)",
              },
            }}
            onClick={() => handleSubmitFilter()}
          >
            Appliquer
          </Button>
        </div>
      </>
    );
  }

  //--------SKELETON--------

  function Filters() {
    return isloading ? (
      <Skeleton variant="rectangular" width="100%" height={45} />
    ) : (
      returnFilters()
    );
  }

  function Media() {
    return (
      <div className={StyledComponents.grid}>
        {(isloading ? Array.from(new Array(12)) : data).map((item, index) => (
          <Box key={index} className={StyledComponents.cards}>
            {/*CARDS*/}
            {item ? (
              <>
                {/* {returnFilters()} */}
                <ProductCard
                  image={item.photo}
                  sku={item.sku}
                  brand={item.brand}
                  name={item.name}
                  stylenum={item.stylenum}
                  price={item.price}
                  diam={item.diameter}
                  width={item.width}
                  season={item.season}
                  color={item.color}
                  finish={item.finish}
                  offset={item.offset}
                  pcd1={item.pcd1}
                  pcd2={item.pcd2}
                  supplier={item.supplier}
                  lifted={item.lifted}
                />
              </>
            ) : (
              <WheelsCardSkeleton />
            )}
          </Box>
        ))}
      </div>
    );
  }

  //-----------PAGINATION--------------------
  function addWheels() {
    let v = getVehicle();
    let wheelfittingnbr = v[0].number + v[0].selectedSize;
    getmorewheels(wheelfittingnbr);

    async function getmorewheels(wheelfittingnbr) {
      await fetch(
        `https://www.wheels-api.com/vehiclewheels/${wheelfittingnbr}/${StartIndex}/${EndIndex}`
      )
        .then((res) => res.json())
        .then(
          (result) => {
            //***Change displayed wheels on LS
            let v = getVehicle();
            v[0].displayedWheels = result.wheels;
            setVehicle(v);

            //****set avalible wheels to display
            async function y(xres) {
              let x = await merge(xres);
              setdata([]);
              setdata(x);
            }
            y(result.wheels);
            setisloading(false);
          },
          (error) => {
            console.log(error);
          }
        );
    }

    async function merge(newData) {
      let x = data;
      for (let i = 0; i < newData.length; i++) {
        x.push(newData[i]);
      }
      return x;
    }

    //console.log(data);
  }

  //----USEREF----

  const myRef = useRef();
  const [myElementIsVisible, setmyElementIsVisible] = useState();

  useEffect(() => {
    // console.log(myElementIsVisible);
    // console.log(myElementIsVisible === true);
    // console.log(StartIndex < TOTALWHEELS && EndIndex < TOTALWHEELS);
    // console.log(StartIndex);
    // console.log(EndIndex);
    // console.log(TOTALWHEELS);
    if (myElementIsVisible === true) {
      if (StartIndex < TOTALWHEELS && EndIndex < TOTALWHEELS) {
        // console.log("LOAD MORE WHEELS");
        //CHANGE INDEX
        let newSI = EndIndex + 1;
        let newEI = newSI + 8;
        setStartIndex(newSI);
        setEndIndex(newEI);
        addWheels();
      }
    }
  }, [myElementIsVisible]);

  useEffect(() => {
    // console.log("myRef", myRef.current);
    const observer = new IntersectionObserver((entries) => {
      const entry = entries[0];
      setmyElementIsVisible(entry.isIntersecting);
      // console.log("entry", entry);
    });
    observer.observe(myRef.current);
  }, []);

  //-----------FORM--------------------
  const [ErrorMessage, setErrorMessage] = useState("");

  return (
    <>
      <form
        className={StyledComponents.form}
        onSubmit={(event) => {
          handleSubmit(event);
        }}
      >
        <h2>Sélectionnez votre véhicule</h2>
        <div className={StyledComponents.formControls}>
          {/* MAKE */}
          <select value={Make} onChange={(e) => getYears(e.target.value)}>
            <option selected hidden>
              MARQUE
            </option>
            {makesData.map((items) => (
              <option key={items.make} value={items.make}>
                {items.make.toUpperCase()}
              </option>
            ))}
          </select>
          {/* YEAR */}
          <select value={Year} onChange={(e) => getModels(e.target.value)}>
            <option selected hidden>
              ANNÉE
            </option>
            {YearItems.map((items) => (
              <option key={items.year} value={items.year}>
                {items.year}
              </option>
            ))}
          </select>
          {/* MODEL */}
          <select value={Model} onChange={(e) => getTrimOption(e.target.value)}>
            <option selected hidden>
              MODÈLE
            </option>
            {ModelItems.map((items) => (
              <option key={items.model} value={items.model}>
                {items.model.toUpperCase()}
              </option>
            ))}
          </select>
          {/* TRIM */}
          <select value={Trim} onChange={(e) => getOption(e.target.value)}>
            <option selected hidden>
              VERSION
            </option>
            {TrimOptionItems.map((items) => (
              <option key={items.trim} value={items.trim}>
                {items.trim.toUpperCase()}
              </option>
            ))}
          </select>
          {/* OPTION */}
          <select value={Option} onChange={(e) => getSizes(e.target.value)}>
            <option hidden>OPTIONS</option>
            {OptionItems.map((items) => {
              if (items.optionName === "All") {
                return (
                  <option key={items.option} value={items.option}>
                    TOUTES LES OPTIONS
                  </option>
                );
              }
              return (
                <option key={items.option} value={items.option}>
                  {items.optionName}
                </option>
              );
            })}
          </select>
          {/* Size */}
          <select value={Size} onChange={(e) => getChosenSize(e.target.value)}>
            <option selected hidden>
              DIAMÈTRE
            </option>
            {AvalibleSizes.map((items) => (
              <option key={items} value={items}>
                {items}
              </option>
            ))}
          </select>

          {/* Submit */}
          <button type="submit">Rechercher</button>
        </div>
        {ErrorMessage}
      </form>

      {/* {Make + " " + Year + " " + Model + " " + Trim + " " + Option + " " + Size} */}

      <div className={StyledComponents.itemsDisplay} style={DisplayItems}>
        {Filters()}
        {Media()}
        <p ref={myRef} style={{ marginTop: "-1200px", zIndex: "-100" }}>
          &nbsp;
        </p>
      </div>
    </>
  );
};

export default SearchByVehicle;
